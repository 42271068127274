<template>
<div class="apCodeUpload-page-content ">
    <div class="main-block position-relative winner-page__hero">
        <div class="position-relative">
            <div class="container-xl">
                <div class="row mb-lg-5 pb-lg-5 ">
                    
                    <div class="col-12 col-lg-6 d-flex justify-content-center flex-column align-items-lg-start align-items-center">
                        <h1 class="mb-3">
                            <!-- <img class="img-fluid" style="border-radius:8px;" :src="`${baseUrl}/lang_images/winner_${$store.state.currentLang}.png`" alt="winner" width="320"/> -->
                            {{tr('winner-congrat')}}
                        </h1>
                        <p class="main-block__hero--p text-center text-lg-left">{{tr('winner-ervenyes-palyazat-eseten')}}</p>
                        <p class="main-block__hero--p  text-center text-lg-left" v-if="winnerType == 'main'">{{ tr('winner-hero-sub-h1-main') }}</p>   
                             <img :src="`${baseUrl}/lang_images/hero-1x-mobile-${$store.state.currentLang}.jpg`" class="img-fluid d-block d-sm-none"/>
                    
                          <img :src="`${baseUrl}/lang_images/winnings/fodij_1x_${$store.state.currentLang}.png`" class="winner-product img-fluid d-none d-sm-block" alt="sheba"/>    
                      <!--  <p class="main-block__hero--p  text-center text-lg-left" v-if="winnerType == 'weekly'">{{ tr('winner-hero-sub-h1-weekly') }}</p>
                        <p class="main-block__hero--p  text-center text-lg-left" v-if="winnerType == 'daily'">{{ tr('winner-hero-sub-h1-daily') }}</p>-->
                        
                        <button v-if="needBtn" class="btn btn-primary mt-4 mb-lg-5 mb-3" v-scroll-to="{ el: '#winnerForm', offset: -80 }">{{tr('kerem-a-nyeremenyem')}}</button>

                        <p class="real-info-txt pb-4 text-center mx-auto text-white d-lg-none d-block">{{tr('winner-hero-info')}}</p>
                    </div>  

                    <!-- IMG - desctop     -->
                    <div class="col-12 col-lg-4 offset-lg-2 align-self-center py-5 d-none d-lg-block">
                        <div class="winnerType-bgr py-xl-5">
                              <!--<img v-if="winnerType == 'daily'" :src="`${baseUrl}/lang_images/winnings/napi_1x_${$store.state.currentLang}.png`" :alt="tr('winnings-img-alt-napi')" class="img-fluid" />
                           <img v-if="winnerType == 'weekly'" :src="`${baseUrl}/lang_images/winnings/heti_1x_${$store.state.currentLang}.png`" :alt="tr('winnings-img-alt-heti')" class="img-fluid position-relative" style="z-index:2;" width="350" />
                             <img v-if="winnerType == 'main'" :src="`${baseUrl}/lang_images/winnings/fodij_1x_${$store.state.currentLang}.png`" alt="pedigree" class="img-fluid" width="400"/>       -->     
                        </div>
                    </div> 
                
                </div>
            </div>
             <div class="p-3 d-none d-lg-block" style="background: #ff000000;">
                <p class="real-info-txt py-4 text-center mx-auto text-white">{{tr('winner-hero-info')}}</p>
            </div>  
        </div>
   </div>
   <!--<div class="text-center mx-auto bg-secondary">
        <button v-if="needBtn" class="btn btn-secondary mt-4 mb-4 d-none mx-auto" v-scroll-to="{ el: '#winnerForm', offset: -80 }">{{tr('kerem-a-nyeremenyem')}}</button>
        <p class="real-info-txt py-4 text-center mx-auto text-primary">{{tr('winner-hero-info')}}</p>
    </div>-->
</div>
</template>

<script>
export default {
    props:['winnerType', 'needBtn']
}
</script>